'use strict';

import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { sha1 } from 'js-sha1';

export function initAmplitudeClient(window: Window, user) {
  const { AMPLITUDE_APIKEY: apiKey } = window.SCW_ENV;
  if (apiKey) {
    amplitude.add(sessionReplayPlugin());
    const hashedUid = user?._id ? sha1(user._id) : undefined;
    if (window.amplitude && hashedUid) {
      window.amplitude.setUserId(hashedUid);
      setAmplitudeGroups(window, user);
      return;
    }
    amplitude.init(apiKey, hashedUid, {
      identityStorage: 'localStorage',
      autocapture: false,
      appVersion: window.SCW_ENV.GIT_COMMIT,
    });
    window.amplitude = amplitude;
  }
}

export function getAmplitudeSessionId(window) {
  return window.amplitude?.getSessionId();
}

export function resetAmplitudeSession(window) {
  window.amplitude.remove('sessionReplayTracking');
  window.amplitude.reset();
}

// PLAT-15453 Set groups for all users
function setAmplitudeGroups(window: Window, user) {
  Object.entries(getAmplitudeGroups(user)).forEach(([group, value]) => {
    window.amplitude.setGroup(group, value);
  });
}

export const getAmplitudeGroups = (user) => {
  const groups: Record<string, Array<string | undefined>> = {};
  // sha1 doesn't like undefined
  if (user?.properties?._cid) {
    groups.company = [sha1(user.properties._cid)];
  }
  if (user?.properties?._tid) {
    groups.team = [sha1(user.properties._tid)];
  }
  // amplitude's setGroup function also doesn't want to accept undefined
  if (user?.properties?.company?.customerPlan) {
    groups.customer_plan = [user.properties.company.customerPlan];
  }
  if (user?.properties?.company?.customerType) {
    groups.customerType = [user.properties.company.customerType];
  }
  if (user?.properties?.company?.vleId) {
    groups.salesforce_id = [user.properties.company.vleId];
  }
  return groups;
};
